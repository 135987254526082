.App {
  text-align: center;
  background-color: #282c34;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

/* COLORS */
.wht {color: whitesmoke;}
.pntr {cursor: pointer;}

/* UTILS */
.rnd-10 {border-radius: 10px;}
.rnd-50 {border-radius: 50%;}
.marg-top-3 {margin-top: 3.5px;}
.marg-top-5 {margin-top: 5px;}
.marg-top-10 {margin-top: 10px;}

.title {font-weight: bolder; margin-left: 5px; color: rgb(252, 250, 249); font-size: 24px; margin: 10px;}
.nav-bar {width: 100%; height: 7%; background-color: rgb(76, 76, 77); color: white; display: grid; grid-template-columns: 50% 50%; place-items: center;}
.nav-bar-btm {width: 100%; height: 7%; background-color: rgb(76, 76, 77); color: white;}
.sec-left {display: inline-flex; place-items: center left; width: 100%; height: 100%;}
.sec-right {display: inline-flex; width: 100%; height: 100%; flex-wrap: wrap; justify-content: flex-end; place-items: center; padding-right: 15px;}
.nav-btn {margin-inline: 10px; font-weight: bolder;}

.bg-headerboard {background-image: url('/public/banner.jpg'); width: 100%; height: 300px; background-repeat: no-repeat; background-size: cover; display: grid; place-items: normal left;}

main {width: 100%; height: auto;}

::-webkit-scrollbar {width: 0.25rem;}
::-webkit-scrollbar-track {background: #1e1e24;}
::-webkit-scrollbar-thumb {background: #6649b8;}

.logo {width: 50%; height: auto;}
.facebook-logo {width: 32px; height: auto; margin-left: 10px;}
.tiktok-logo {width: 35px; height: auto; margin-left: 10px;}
.email-logo {width: 32px; height: auto; margin-left: 10px;}

.about-sec {padding: 10px;}

.display-card {margin: 10px; height: fit-content;}
.card-image {height: auto; width: 100%;}
.card-grid{width:100%; max-width: 900px; margin: auto; display: grid; grid-template-columns: 50% 50%;}
.grid-colomn {padding-inline: 5px;}
.under-grid-card {width:100%; max-width: 900px; margin: auto;}

.centered-display {height: 100%; display: grid; place-items: center;}
.nothing-card {background-color: whitesmoke; height: auto; padding: 10px; border-radius: 10px;}
.nothing-card p {font-weight: bolder;}
.nothing-card i {font-size: xx-large; height: auto;}

video {border-radius: 10px;}

@media screen and (max-width: 701px) {
  .bg-headerboard {height: 250px;}
  .logo {width: 80%;}
}
